import type DataObject from 'o365.modules.DataObject.ts';
import type { IRequestOptions, RequestOperation } from 'o365.modules.DataObject.DataHandler.ts';
import type Devex from 'o365.modules.Devex.ts';
import AppSettings from 'o365.pwa.modules.client.AppSettingsIDB.ts';
import { app } from 'o365.modules.configs.ts';
import type { AppState, AppStateOverride } from 'o365.pwa.types.ts';
import $t from 'o365.modules.translate.ts';

export interface IOfflineRequestOptions { 
    skipAbortCheck?: boolean,
    appStateOverride?: AppStateOverride
}

export interface IDefaultOfflineRequestOptions {
    skipAbortCheck: boolean,
}

const defaultOfflineRequestOptions: IDefaultOfflineRequestOptions = {
    skipAbortCheck: false,
};

export default class Offline {
    private _dataObject: DataObject;
    private _dataHandlerRequest: Function;
    private _getOriginalOptions: Function;

    public whereObject: Devex;
    public appStateOverride?: AppState = undefined;

    constructor(dataObject: DataObject) {
        this._dataObject = dataObject;
        this._getOriginalOptions = dataObject.recordSource.getOptions;
        this._dataHandlerRequest = dataObject.dataHandler.request;

        this.whereObject = null;

        this._dataObject.dataHandler.request = this._request.bind(this);
        this._dataObject.recordSource.getOptions = this._getOptions.bind(this);
        this._dataObject.recordSource.whereObject = this.whereObject;

        if (this._dataObject.generateOfflineData) {
            // TODO: Add columns
            this._dataObject.fields.addField({
                fieldName: 'o365_PrimKey',
                dataType: 'uniqueidentifier',
                hasDefault: true,
                nullable: false,
                maxLength: 36,
                joined: false,
                caption: $t('PrimKey')
            });
            // CCTL
            // Created
            // CreatedBy_ID
            // Updated
            // UpdatedBy_ID
            // JsonData
            // Type
            // ErrorMessage
            // Owner_ID
            // LastCheckIn
            // AppID
            // JsonDataVersion
            // ExternalRef
            // CreatedBy
            // UpdatedBy
            
            if (this._dataObject.isFileTable ?? false) {
                // TODO: Add columns
                // FileName
                // FileSize
                // FileUpdated
                // FileRef
                // Extension
                // CheckedOut
                // CheckedOutBy_ID
                // ParentRef
            }
        }
    }

    private async _request<T extends IRequestOptions>(pType: RequestOperation, pData: T, pHeaders?: Headers, pOptions?: IOfflineRequestOptions) {
        const vHeaders = pHeaders ?? new Headers();
        const vOptions = Object.assign({}, defaultOfflineRequestOptions, pOptions ?? {});

        const appStateOverride: AppStateOverride = vOptions.appStateOverride ?? this.appStateOverride ?? (await AppSettings.getOrRetrieveOrCreateInstance(app.id)).appState;

        vHeaders.set('O365-App-State-Override', appStateOverride);

        const response = await this._dataHandlerRequest.bind(this._dataObject.dataHandler).call(this, pType, pData, vHeaders, pOptions);

        return response;
    }

    private _getOptions() {
        const options = this._getOriginalOptions();
        options.dataSourceId = this._dataObject.id;
        options.whereObject = this._dataObject.recordSource.whereObject;
        options.filterObject = this._dataObject.filterObject.filterObject;
        options.masterDetailObject = this._dataObject.masterDetails.getFilterObject();

        return options;
    }
}
